import moment from 'moment-timezone';
import _ from 'lodash';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { CommonModule } from '@angular/common';
import { formatDistance } from 'date-fns';
import { uiTimezone } from './components/voltaire/constants';

@Pipe({
    name: 'cstDate'
})
export class CstDatePipe implements PipeTransform {

    private formats = {
        date: 'MM/DD/YYYY',
        timeMinutesZone: 'M/D/YY HH:mm (zz)',
        timeSecondsZone: 'M/D/YY HH:mm:ss (zz)'
    }

    /**
     * Render the date as equivalent time in present Central offset using supplied formatter.
     * See `constants.ts` for the date formatting strings to use throughout the app.
     */
    transform(value: any, format?: keyof typeof this.formats) {
        const dateFormat = this.formats[format ?? 'date'];
        if (value) {
            const cstTime = moment.tz(value, uiTimezone);
            return cstTime.format(dateFormat);
        }
        return;
    }
}

@Pipe({
    name: 'toWordsAgo'
})
export class ToWordsAgo implements PipeTransform {
    transform(value: any, ...args: any[]) {
        return formatDistance(value, new Date());
    }
}

@Pipe({
    name: 'sentenceCase'
})
export class SentenceCasePipe implements PipeTransform {
    transform(value: string) {
        return _.capitalize(value);
    }
}
type KeyUnion<T> = keyof T;

@Pipe({
    name: 'typedKeyValue'
})
export class TypedKeyValuePipe implements PipeTransform {
    transform<T extends Object>(value: T): { key: KeyUnion<T>, value: T[KeyUnion<T>] }[] {
        return Object.entries(value).map(([key, val]) => ({ key: key as KeyUnion<T>, value: val }));
    }
}

@NgModule({
    declarations: [
        ToWordsAgo,
        CstDatePipe,
        SentenceCasePipe,
        TypedKeyValuePipe
    ],
    imports: [CommonModule],
    exports: [
        ToWordsAgo,
        CstDatePipe,
        SentenceCasePipe,
        TypedKeyValuePipe
    ]
})
export class MiscPipe { }