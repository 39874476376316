/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Current API
 * An Asp.Net Core WebAPI
 * OpenAPI spec version: v1
 */
import {
  HttpClient
} from '@angular/common/http'
import type {
  HttpContext,
  HttpHeaders,
  HttpParams
} from '@angular/common/http'
import {
  Injectable
} from '@angular/core'
import {
  Observable
} from 'rxjs'
import type {
  DeleteApiSimulatorContainerParams,
  DeleteApiSimulatorPlanParams,
  GetApiSimulatorContainerInfoParams,
  Plan,
  PostApiSimulatorContainerParams,
  RunRequest,
  Settings,
  SimulationRunDoc,
  TaskInfo,
  TaskRequest
} from '../schemas'



type HttpClientOptions = {
  headers?: HttpHeaders | {
      [header: string]: string | string[];
  };
  context?: HttpContext;
  observe?: any;
  params?: HttpParams | {
    [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
  };
  reportProgress?: boolean;
  responseType?: any;
  withCredentials?: boolean;
};



@Injectable({ providedIn: 'root' })
export class SimulatorService {
  constructor(
    private http: HttpClient,
  ) {} postApiSimulatorRun<TData = void>(
    runRequest: RunRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `/api/simulator/run`,
      runRequest,options
    );
  }
 getApiSimulatorTasks<TData = TaskInfo[]>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `/api/simulator/tasks`,options
    );
  }
/**
 * @summary Allow user to request stop or removal of long running task
 */
 postApiSimulatorTaskManage<TData = boolean>(
    taskRequest: TaskRequest, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `/api/simulator/task/manage`,
      taskRequest,options
    );
  }
 getApiSimulatorSettings<TData = Settings>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `/api/simulator/settings`,options
    );
  }
/**
 * @summary Get a list of all simulation containers along with META information about each (later)
 */
 getApiSimulatorContainers<TData = string[]>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `/api/simulator/containers`,options
    );
  }
/**
 * @summary Get the simulation information document for the specified container
 */
 getApiSimulatorContainerInfo<TData = SimulationRunDoc>(
    params?: GetApiSimulatorContainerInfoParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `/api/simulator/container/info`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }
/**
 * @summary Allow user to set the submission storage for this session
 */
 postApiSimulatorContainer<TData = Settings>(
    params?: PostApiSimulatorContainerParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `/api/simulator/container`,undefined,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }
 deleteApiSimulatorContainer<TData = void>(
    params?: DeleteApiSimulatorContainerParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.delete<TData>(
      `/api/simulator/container`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }
 getApiSimulatorPlans<TData = Plan[]>(
     options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.get<TData>(
      `/api/simulator/plans`,options
    );
  }
 postApiSimulatorPlan<TData = Plan>(
    plan: Plan, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.post<TData>(
      `/api/simulator/plan`,
      plan,options
    );
  }
/**
 * @summary Delete the plan with the ID specified
 */
 deleteApiSimulatorPlan<TData = boolean>(
    params?: DeleteApiSimulatorPlanParams, options?: HttpClientOptions
  ): Observable<TData>  {
    return this.http.delete<TData>(
      `/api/simulator/plan`,{
    ...options,
        params: {...params, ...options?.params},}
    );
  }
};

export type PostApiSimulatorRunClientResult = NonNullable<void>
export type GetApiSimulatorTasksClientResult = NonNullable<TaskInfo[]>
export type PostApiSimulatorTaskManageClientResult = NonNullable<boolean>
export type GetApiSimulatorSettingsClientResult = NonNullable<Settings>
export type GetApiSimulatorContainersClientResult = NonNullable<string[]>
export type GetApiSimulatorContainerInfoClientResult = NonNullable<SimulationRunDoc>
export type PostApiSimulatorContainerClientResult = NonNullable<Settings>
export type DeleteApiSimulatorContainerClientResult = NonNullable<void>
export type GetApiSimulatorPlansClientResult = NonNullable<Plan[]>
export type PostApiSimulatorPlanClientResult = NonNullable<Plan>
export type DeleteApiSimulatorPlanClientResult = NonNullable<boolean>
