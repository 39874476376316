import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HighlightModule } from "ngx-highlightjs";
import { MomentModule } from "ngx-moment";
import { MiscPipe } from "./misc.pipe";
import { PrimeNgModule } from "./primeng.module";
import { HighlightLineNumbers } from "ngx-highlightjs/line-numbers";


@NgModule({
    imports:[
        CommonModule,
        MomentModule,
        HighlightModule,
        HighlightLineNumbers,
        MiscPipe,
        PrimeNgModule
    ],
    declarations:[],
    exports:[
        CommonModule,
        FormsModule,
        MomentModule,
        HighlightModule,
        HighlightLineNumbers,
        MiscPipe,
        PrimeNgModule
    ]
})
export class SharedModule{}