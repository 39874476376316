@if(breadcrumbs.length){
<div class="mb-2">
  <p-breadcrumb class="max-w-full mb-2" [model]="breadcrumbs" />
</div>
}
<p-panel [showHeader]="false">
    <div style="max-width:680px">
        <markdown [src]="'assets/docs/' + filename" />
    </div>
</p-panel>
